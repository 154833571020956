<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Register Nasabah</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <a-button
          type="button"
          class="btn btn-outline-primary float-right"
          @click="connector"
        >
          Tambah Data
        </a-button>
      </template>
      <a-table
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 20000, y: 400 }"
        :pagination="false"
        size="small"
        :loading="tableLoading"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-icon type="edit" style="cursor: pointer;" class="text-warning" @click="sendToEdit(record)"/>
          <a-divider type="vertical" />
          <a-icon type="delete" style="cursor: pointer;" class="text-danger" @click="showDeleteConfirm(record)"/>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="statusperkawinan" slot-scope="text, record">
          {{ statusperkawinan[record.statusperkawinan] }}
        </span>
        <span slot="jenisidentitas" slot-scope="text, record">
          {{ jenisidentitas[record.jenisidentitas] }}
        </span>
        <span slot="seumurhidup" slot-scope="text, record">
          {{ yatidak[record.seumurhidup] }}
        </span>
        <span slot="blacklist" slot-scope="text, record">
          {{ yatidak[record.blacklist] }}
        </span>
        <span slot="perorangan" slot-scope="text, record">
          {{ perorangan[record.Perorangan] }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
        <span slot="tgllahir" slot-scope="text, record">
          {{ formatDate(record.tgllahir) }}
        </span>
        <span slot="expiredidentitas" slot-scope="text, record">
          {{ formatDate(record.expiredidentitas) }}
        </span>
      </a-table>
      <a-pagination class="mt-4" size="small" v-model="currentPage" :page-size.sync="currentPageSize" :total="totalpaginationdata" :pageSizeOptions="['10', '30', '50']" @showSizeChange="showSizeChangePaginationFunc" @change="changePaginationFunc" show-size-changer :show-total="(total, range) => `${range[0]}-${range[1]} of ${total} items`"/>
    </a-card>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    width: 70,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    // width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    dataIndex: 'kode',
    rowKey: 'kode',
    title: 'Kode',
    // width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  }, {
    title: 'namaibu',
    dataIndex: 'namaibu',
    scopedSlots: { customRender: 'namaibu' },
    // width: 100,
  }, {
    title: 'kelamin',
    dataIndex: 'kelamin',
    scopedSlots: { customRender: 'kelamin' },
    // width: 100,
  }, {
    title: 'golongandarah',
    dataIndex: 'golongandarah',
    scopedSlots: { customRender: 'golongandarah' },
    // width: 100,
  }, {
    title: 'tgllahir',
    dataIndex: 'tgllahir',
    scopedSlots: { customRender: 'tgllahir' },
    // width: 100,
  }, {
    title: 'tempatlahir',
    dataIndex: 'tempatlahir',
    scopedSlots: { customRender: 'tempatlahir' },
    // width: 100,
  }, {
    title: 'statusperkawinan',
    dataIndex: 'statusperkawinan',
    scopedSlots: { customRender: 'statusperkawinan' },
    // width: 100,
  }, {
    title: 'ktp',
    dataIndex: 'ktp',
    scopedSlots: { customRender: 'ktp' },
    // width: 100,
  }, {
    title: 'tglktp',
    dataIndex: 'tglktp',
    scopedSlots: { customRender: 'tglktp' },
    // width: 100,
  }, {
    title: 'agama',
    dataIndex: 'agama',
    scopedSlots: { customRender: 'agama' },
    // width: 100,
  }, {
    title: 'pekerjaan',
    dataIndex: 'pekerjaan',
    scopedSlots: { customRender: 'pekerjaan' },
    // width: 100,
  }, {
    title: 'alamat',
    dataIndex: 'alamat',
    scopedSlots: { customRender: 'alamat' },
    // width: 100,
  }, {
    title: 'alamat2',
    dataIndex: 'alamat2',
    scopedSlots: { customRender: 'alamat2' },
    // width: 100,
  }, {
    title: 'kodepos',
    dataIndex: 'kodepos',
    scopedSlots: { customRender: 'kodepos' },
    // width: 100,
  }, {
    title: 'telepon',
    dataIndex: 'telepon',
    scopedSlots: { customRender: 'telepon' },
    // width: 100,
  }, {
    title: 'fax',
    dataIndex: 'fax',
    scopedSlots: { customRender: 'fax' },
    // width: 100,
  }, {
    title: 'kodya',
    dataIndex: 'kodya',
    scopedSlots: { customRender: 'kodya' },
    // width: 100,
  }, {
    title: 'kecamatan',
    dataIndex: 'kecamatan',
    scopedSlots: { customRender: 'kecamatan' },
    // width: 100,
  }, {
    title: 'kelurahan',
    dataIndex: 'kelurahan',
    scopedSlots: { customRender: 'kelurahan' },
    // width: 100,
  }, {
    title: 'rtrw',
    dataIndex: 'rtrw',
    scopedSlots: { customRender: 'rtrw' },
    // width: 100,
  }, {
    title: 'alamatkantor',
    dataIndex: 'alamatkantor',
    scopedSlots: { customRender: 'alamatkantor' },
    // width: 100,
  }, {
    title: 'teleponkantor',
    dataIndex: 'teleponkantor',
    scopedSlots: { customRender: 'teleponkantor' },
    // width: 100,
  }, {
    title: 'faxkantor',
    dataIndex: 'faxkantor',
    scopedSlots: { customRender: 'faxkantor' },
    // width: 100,
  }, {
    title: 'kodyakantor',
    dataIndex: 'kodyakantor',
    scopedSlots: { customRender: 'kodyakantor' },
    // width: 100,
  }, {
    title: 'kecamatankantor',
    dataIndex: 'kecamatankantor',
    scopedSlots: { customRender: 'kecamatankantor' },
    // width: 100,
  }, {
    title: 'kelurahankantor',
    dataIndex: 'kelurahankantor',
    scopedSlots: { customRender: 'kelurahankantor' },
    // width: 100,
  }, {
    title: 'rtrwkantor',
    dataIndex: 'rtrwkantor',
    scopedSlots: { customRender: 'rtrwkantor' },
    // width: 100,
  }, {
    title: 'kodeposkantor',
    dataIndex: 'kodeposkantor',
    scopedSlots: { customRender: 'kodeposkantor' },
    // width: 100,
  }, {
    title: 'path',
    dataIndex: 'path',
    scopedSlots: { customRender: 'path' },
    // width: 100,
  }, {
    title: 'ao',
    dataIndex: 'ao',
    scopedSlots: { customRender: 'ao' },
    // width: 100,
  }, {
    title: 'path1',
    dataIndex: 'path1',
    scopedSlots: { customRender: 'path1' },
    // width: 100,
  }, {
    title: 'tglregister',
    dataIndex: 'tglregister',
    scopedSlots: { customRender: 'tglregister' },
    // width: 100,
  }, {
    title: 'namalembaga',
    dataIndex: 'namalembaga',
    scopedSlots: { customRender: 'namalembaga' },
    // width: 100,
  }, {
    title: 'alamatlembaga',
    dataIndex: 'alamatlembaga',
    scopedSlots: { customRender: 'alamatlembaga' },
    // width: 100,
  }, {
    title: 'noaktependirian',
    dataIndex: 'noaktependirian',
    scopedSlots: { customRender: 'noaktependirian' },
    // width: 100,
  }, {
    title: 'nonpwp',
    dataIndex: 'nonpwp',
    scopedSlots: { customRender: 'nonpwp' },
    // width: 100,
  }, {
    title: 'kodyalembaga',
    dataIndex: 'kodyalembaga',
    scopedSlots: { customRender: 'kodyalembaga' },
    // width: 100,
  }, {
    title: 'kecamatanlembaga',
    dataIndex: 'kecamatanlembaga',
    scopedSlots: { customRender: 'kecamatanlembaga' },
    // width: 100,
  }, {
    title: 'kelurahanlembaga',
    dataIndex: 'kelurahanlembaga',
    scopedSlots: { customRender: 'kelurahanlembaga' },
    // width: 100,
  }, {
    title: 'alamattinggal',
    dataIndex: 'alamattinggal',
    scopedSlots: { customRender: 'alamattinggal' },
    // width: 100,
  }, {
    title: 'telepontinggal',
    dataIndex: 'telepontinggal',
    scopedSlots: { customRender: 'telepontinggal' },
    // width: 100,
  }, {
    title: 'faxtinggal',
    dataIndex: 'faxtinggal',
    scopedSlots: { customRender: 'faxtinggal' },
    // width: 100,
  }, {
    title: 'kodyatinggal',
    dataIndex: 'kodyatinggal',
    scopedSlots: { customRender: 'kodyatinggal' },
    // width: 100,
  }, {
    title: 'kecamatantinggal',
    dataIndex: 'kecamatantinggal',
    scopedSlots: { customRender: 'kecamatantinggal' },
    // width: 100,
  }, {
    title: 'kelurahantinggal',
    dataIndex: 'kelurahantinggal',
    scopedSlots: { customRender: 'kelurahantinggal' },
    // width: 100,
  }, {
    title: 'rtrwtinggal',
    dataIndex: 'rtrwtinggal',
    scopedSlots: { customRender: 'rtrwtinggal' },
    // width: 100,
  }, {
    title: 'kodepostinggal',
    dataIndex: 'kodepostinggal',
    scopedSlots: { customRender: 'kodepostinggal' },
    // width: 100,
  }, {
    title: 'merchant',
    dataIndex: 'merchant',
    scopedSlots: { customRender: 'merchant' },
    // width: 100,
  }, {
    title: 'tglupdate',
    dataIndex: 'tglupdate',
    scopedSlots: { customRender: 'tglupdate' },
    // width: 100,
  }, {
    title: 'detailpekerjaan',
    dataIndex: 'detailpekerjaan',
    scopedSlots: { customRender: 'detailpekerjaan' },
    // width: 100,
  }, {
    title: 'blacklist',
    dataIndex: 'blacklist',
    scopedSlots: { customRender: 'blacklist' },
    // width: 100,
  }, {
    title: 'desa',
    dataIndex: 'desa',
    scopedSlots: { customRender: 'desa' },
    // width: 100,
  }, {
    title: 'hubunganbank',
    dataIndex: 'hubunganbank',
    scopedSlots: { customRender: 'hubunganbank' },
    // width: 100,
  }, {
    title: 'tempatbekerja',
    dataIndex: 'tempatbekerja',
    scopedSlots: { customRender: 'tempatbekerja' },
    // width: 100,
  }, {
    title: 'namapasangan',
    dataIndex: 'namapasangan',
    scopedSlots: { customRender: 'namapasangan' },
    // width: 100,
  }, {
    title: 'jumlahanak',
    dataIndex: 'jumlahanak',
    scopedSlots: { customRender: 'jumlahanak' },
    // width: 100,
  }, {
    title: 'kelurahan1',
    dataIndex: 'kelurahan1',
    scopedSlots: { customRender: 'kelurahan1' },
    // width: 100,
  }, {
    title: 'kecamatan1',
    dataIndex: 'kecamatan1',
    scopedSlots: { customRender: 'kecamatan1' },
    // width: 100,
  }, {
    title: 'kodya1',
    dataIndex: 'kodya1',
    scopedSlots: { customRender: 'kodya1' },
    // width: 100,
  }, {
    title: 'provinsi1',
    dataIndex: 'provinsi1',
    scopedSlots: { customRender: 'provinsi1' },
    // width: 100,
  }, {
    title: 'hp',
    dataIndex: 'hp',
    scopedSlots: { customRender: 'hp' },
    // width: 100,
  }, {
    title: 'namaperusahaan',
    dataIndex: 'namaperusahaan',
    scopedSlots: { customRender: 'namaperusahaan' },
    // width: 100,
  }, {
    title: 'username',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
    // width: 100,
  }, {
    title: 'email',
    dataIndex: 'email',
    scopedSlots: { customRender: 'email' },
    // width: 100,
  }, {
    title: 'npwp',
    dataIndex: 'npwp',
    scopedSlots: { customRender: 'npwp' },
    // width: 100,
  }, {
    title: 'gaji',
    dataIndex: 'gaji',
    scopedSlots: { customRender: 'gaji' },
    // width: 100,
  }, {
    title: 'pendapatanlainnya',
    dataIndex: 'pendapatanlainnya',
    scopedSlots: { customRender: 'pendapatanlainnya' },
    // width: 100,
  }, {
    title: 'tujuanpembukaan',
    dataIndex: 'tujuanpembukaan',
    scopedSlots: { customRender: 'tujuanpembukaan' },
    // width: 100,
  }, {
    title: 'keterangantujuanpembukaan',
    dataIndex: 'keterangantujuanpembukaan',
    scopedSlots: { customRender: 'keterangantujuanpembukaan' },
    // width: 100,
  }, {
    title: 'usahanontunai',
    dataIndex: 'usahanontunai',
    scopedSlots: { customRender: 'usahanontunai' },
    // width: 100,
  }, {
    title: 'jumlahnontunai',
    dataIndex: 'jumlahnontunai',
    scopedSlots: { customRender: 'jumlahnontunai' },
    // width: 100,
  }, {
    title: 'usahatunai',
    dataIndex: 'usahatunai',
    scopedSlots: { customRender: 'usahatunai' },
    // width: 100,
  }, {
    title: 'jumlahtunai',
    dataIndex: 'jumlahtunai',
    scopedSlots: { customRender: 'jumlahtunai' },
    // width: 100,
  }, {
    title: 'namabank1',
    dataIndex: 'namabank1',
    scopedSlots: { customRender: 'namabank1' },
    // width: 100,
  }, {
    title: 'rekeningbank1',
    dataIndex: 'rekeningbank1',
    scopedSlots: { customRender: 'rekeningbank1' },
    // width: 100,
  }, {
    title: 'namabank2',
    dataIndex: 'namabank2',
    scopedSlots: { customRender: 'namabank2' },
    // width: 100,
  }, {
    title: 'rekeningbank2',
    dataIndex: 'rekeningbank2',
    scopedSlots: { customRender: 'rekeningbank2' },
    // width: 100,
  }, {
    title: 'instansi',
    dataIndex: 'instansi',
    scopedSlots: { customRender: 'instansi' },
    // width: 100,
  }, {
    title: 'nip',
    dataIndex: 'nip',
    scopedSlots: { customRender: 'nip' },
    // width: 100,
  }, {
    title: 'jenisdebitur',
    dataIndex: 'jenisdebitur',
    scopedSlots: { customRender: 'jenisdebitur' },
    // width: 100,
  }, {
    title: 'jenisidentitas',
    dataIndex: 'jenisidentitas',
    scopedSlots: { customRender: 'jenisidentitas' },
    // width: 100,
  }, {
    title: 'gelar',
    dataIndex: 'gelar',
    scopedSlots: { customRender: 'gelar' },
    // width: 100,
  }, {
    title: 'namaidentitas',
    dataIndex: 'namaidentitas',
    scopedSlots: { customRender: 'namaidentitas' },
    // width: 100,
  }, {
    title: 'ktppasangan',
    dataIndex: 'ktppasangan',
    scopedSlots: { customRender: 'ktppasangan' },
    // width: 100,
  }, {
    title: 'tgllahirpasangan',
    dataIndex: 'tgllahirpasangan',
    scopedSlots: { customRender: 'tgllahirpasangan' },
    // width: 100,
  }, {
    title: 'pisahharta',
    dataIndex: 'pisahharta',
    scopedSlots: { customRender: 'pisahharta' },
    // width: 100,
  }, {
    title: 'tingkat_resiko',
    dataIndex: 'tingkat_resiko',
    scopedSlots: { customRender: 'tingkat_resiko' },
    // width: 100,
  }, {
    title: 'sandibank',
    dataIndex: 'sandibank',
    scopedSlots: { customRender: 'sandibank' },
    // width: 100,
  }, {
    title: 'jenisbadanusaha',
    dataIndex: 'jenisbadanusaha',
    scopedSlots: { customRender: 'jenisbadanusaha' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  /* Data variable vue */
  data() {
    return {
      tableLoading: false,
      currentPage: 1,
      currentPageSize: 10,
      totalpaginationdata: 0,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'TableRegisterNasabah',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      dataFagama: [],
      data,
      columns,
      datenow: '',
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('cbsjombangeditregisternasabahdata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    changePaginationFunc(p1, p2) {
      this.currentPage = p1
      this.getAllData()
      // console.log('p1', p1)
      // console.log('p2', p2)
      // console.log('currentPage', this.currentPage)
    },
    showSizeChangePaginationFunc(p1, p2) {
      this.getAllData()
      // console.log('currentPageSize', this.currentPageSize)
      // console.log('p1', p1)
      // console.log('p2', p2)
    },
    connector() {
      localStorage.setItem('cbsjombangeditregisternasabahdata', JSON.stringify({ id: '' }))
      this.$router.push('/cbsjombang/registernasabah/form')
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.getAllData()
    },
    async getAllData() {
      this.tableLoading = true
      // console.log('this.currentPageSize', this.currentPageSize)
      // console.log('this.currentPage', this.currentPage)
      var response = await lou.customUrlGet('RegisterNasabah?page=' + this.currentPage + '&perPage=' + this.currentPageSize)
      if (response) {
        this.tableLoading = false
        this.data = response.data
        this.totalpaginationdata = response.totaldata || 0
      } else {
        this.tableLoading = false
      }
    },
    async getAgamaF() {
      // var res = await lou.customUrlGet('agama')
      // this.dataFagama = res.data
      // this.$perpus.searchObj()
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      localStorage.setItem('cbsjombangeditregisternasabahdata', JSON.stringify(edata))
      this.$router.push('/cbsjombang/registernasabah/form')
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('RegisterNasabah/' + deldata.kode)
          this.data = response.data
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
